// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const Home = (props) => {
  const state = useSelector((state) => state);

  const [show, setShow] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isMyNft, setIsMyNft] = useState("my");
  const [dataNft, setDataNft] = useState({});
  const handleClose = () => setShow(false);

  const colors = [
    // "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/8f22b29d34e24238ad80b24b9ad97503%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230506130729.jpg",
    "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/4cdce2af289045b9bfce6f08d8fbba1460c05ec3670f2.png",
    "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/45dccecd71434951ac726d21dec73891%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
  ];
  const delay = 2500;

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  // useEffect(() => {
  //   resetTimeout();
  //   timeoutRef.current = setTimeout(
  //     () =>
  //       setIndex((prevIndex) =>
  //         prevIndex === colors.length - 1 ? 0 : prevIndex + 1
  //       ),
  //     delay
  //   );
  // }, [1000]);

  return (
    <div className="site-main-content__home">
      <p style={{ color: 'rgb(255 75 75)', fontSize: 18 }} className="ffffeasd">
        Owning 12 NFTs to Get an Animated NFT
      </p>
      <p style={{ color: '#ededed', fontSize: 15 }} className="ffffeasd">
        <div>
          * Latest Update *
          <br />
          🛎️ Announcement to Our Valued Community: Welcoming the Noel and New Year 2024 Program with Exciting NFTS Minting and Rewards!
          <br />
          Effective from 20:00 on November 25th to January 5th, 2024, we extend a warm invitation to our community to participate in the special Noel and New Year program on the NFTS platform.
          <br />
          🎁 Special NFTS Rewards for NFT Minting:<br />
          - Mint 12 NFTS and receive an additional 1 NFTS. Updates will be reflected in your account after minting and mining.
          <br />
          - Mint 36 NFTS and receive an additional 4 NFTS. Updates will be reflected in your account after minting and mining.
          <br />
          - Mint 72 NFTS and receive an additional 8 NFTS. Updates will be reflected in your account after minting and mining.
          <br />
          - Mint 120 NFTS and receive an additional 12 NFTS. Updates will be reflected in your account after minting and mining.
          <br />
          - Mint 200 NFTS and receive an additional 25 NFTS. Updates will be reflected in your account after minting and mining.
          <br />
          🛎️ Important Note:<br />
          These special rewards are a token of our appreciation for your continued support and engagement with the NFTS platform. We want to make this festive season even more memorable for our dedicated community members.
          <br />
          🎉 Join us in Celebrating the Festive Season:<br />
          As we usher in the spirit of Noel and the New Year, we invite you to participate actively in this special program. Let's make this season of joy and giving even more exciting together!
          <br />
          🛎️ Thank you for Being a Part of NFTS!<br />
          Your participation has been the cornerstone of our success. We sincerely thank you for your trust, enthusiasm, and commitment to the NFTS community. Together, let's continue to create memorable moments and exciting experiences.
          <br />
          🎊 Wishing You a Joyous Noel and a Prosperous New Year 2024!<br />
          May this festive season bring you happiness, prosperity, and countless moments of joy. We look forward to celebrating and creating more wonderful memories with you in the coming year.
          <br />
          🛎️ **With Warmest Regards,
          The NFTS Team**
        </div>
        <div>
          Contract Token : <a target="_blank" href="https://bscscan.com/address/0x7c0d7d503121a4c2f2873450fb60e230e7fb6840">0x7c0d7d503...e230e7fb6840</a>
        </div>
        <div>
          NFT contract : <a target="_blank" href="https://bscscan.com/address/0x154dba6abc92562b9358eefe75aee2541498a28b">0x154dba6ab...2541498a28b</a>
        </div>
        <p className="ksksksakafg">
          1/FNFTS: Unlocking the Potential
        </p>
        <br />
        FNFTS is a blockchain bank and incubator working in partnership with major crypto and NFT players in Singapore, Hong Kong, and various European countries to promote digital assets on a large scale. With a focus on digital technology and blockchain, FNFTS explores significant developments in the financial technology sector, DeFi infrastructure, core NFT products, Web3, and NFTS.
        <br />
        <p className="ksksksakafg">
          2/ Introduction to the operating model
        </p>
        <br />
        FNFTS has created an NFT mining ecosystem on the BSC blockchain. Users can claim their NFTS on the Dapp, with a total of 48,000 rare copies to be issued out of a total of 21 billion tokens. The number of tokens is intentionally limited, and FNFTS tokens will be burned using advanced technology by the development team. FNFT represents the 12 zodiac animals associated with everyone's birth year, each with unique characteristics and traits. The gas cost for minting an NFT starts at 0.005 BNB and increases by 0.00001 BNB for every 500 NFTs minted. Your NFTS can be seen in your wallet, along with the contract and specific, transparent token details. You can store them in your wallet and, to optimize, stake your NFTS to generate FNFTS tokens with a value of 0.1% per day. Collect all 12 zodiac animals to gain substantial value for your account.
        <br />
        <p className="ksksksakafg">
          3/ Introduction of eco model and promotion mode of FNFTS
        </p>
        <br />
        Users mine the initial NFTS character on the Dapp, starting with LV0 by minting a character corresponding to their birth year. NFTS will be upgraded as you connect to increase the strength of your NFTS. The more people you introduce, the stronger your NFTS become, and the faster your token generation rate.
        <br />
        Your minted NFTS will be displayed directly in your personal wallet. You can stake your NFTS to generate FNFTS tokens that can be in your wallet or withdraw to your personal wallet.
        <br />
        Participate in Twitter by liking, following, tweeting, and receiving BNB.
        Join the YouTube channel by subscribing to receive BNB.
        <br />
        Join Telegram to receive information from the platform and earn BNB.
        <br />
        The number of FNFTS tokens will only be mined when you take your NFTS for mining. User participation does not require any KYC information or activities; we bring the community a vision of DAPP, Decentralized, and Web3. You only need to log in to the Dapp and register with wallet addresses through wallets such as Safepal, Metamask, TockenPocket, etc.
        <br />
        Circulating FNFTS Tokens
        <br />
        Total Circulation: 21 billion (an estimated 11 billion tokens will be burned).
        <br />
        Participation in NFTS upgrade will burn mined tokens, and the mining output will decrease by 10% for every 1 billion mined.
        <br />

        <p className="ksksksakafg">
          4/ FNFTS  Development plan
        </p>
        <br />
        P1: Opening character NFTS mining, NFTS mining
        <br />
        P2: Burn FNFTS and NFTS tokens with NFTS upgrade steps
        <br />
        P3: Expand the ecosystem to increase asset value and benefit the community
        <br />
        P4: The mining process reaches 6 billion tokens; tokens will be listed on major exchanges such as MEXC, Huobi, Pancakeswap.
        <br />
      </p>
      <div className="alalkks bg_box" style={{ display: 'inherit', marginTop: 20 }}>
        <button class="btn btn-primary mb-2" style={{ width: "100%" }}><a href="/invite" style={{ textDecoration: "none", color: "#fff" }}>Increase mining productivity FNFT</a></button>
        <span>Note:</span>
        <br />
        <span>
          Every 1 nft will have a default mining yield of 0.1%/day.
          to increase mining productivity. You need to refer more friends to join. for every 1 participant productivity will increase by 0.1%.
          max 1%/day.
        </span>
      </div>
    </div>
  );
};

export default Home;

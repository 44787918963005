// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {
  getInfoBuyNfts, getNft, stakeNftWithdraw,
  stakeNft, getDataStakeNft, approveAllStakingNft,
  getStoryNfts, unStakeNft, getBalanceFnft
} from "service";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

const LoadingButton = ({ className }) => {
  return (
    <button className={className} style={{ width: "100%" }}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

let counda = 0;

const Home = (props) => {
  const state = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const [priceBuy, setPriceBuy] = useState(0);
  const [profit, setProfit] = useState(0);
  const [balanceFnft, setBalanceFnft] = useState(0);
  const [limit, setLimit] = useState(0);
  const [show, setShow] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isMyNft, setIsMyNft] = useState([]);
  const [dataNft, setDataNft] = useState({});
  const [dataStakeNft, setDataStakeNft] = useState({});
  const [dataBuy, setDataBuy] = useState({});
  const [dataTotalBuy, setDataTotalBuy] = useState({});
  const handleClose = () => setShow(false);

  const infoBuy = [
    {
      round: 1,
      nfts: 230,
      price: 0.005
    },
    {
      round: 2,
      nfts: 254,
      price: 0.00551
    },
    {
      round: 3,
      nfts: 267,
      price: 0.00579
    },
    {
      round: 4,
      nfts: 280,
      price: 0.00608
    },
    {
      round: 5,
      nfts: 294,
      price: 0.00638
    },
    {
      round: 6,
      nfts: 309,
      price: 0.0067
    },
    {
      round: 7,
      nfts: 324,
      price: 0.00704
    },
    {
      round: 8,
      nfts: 340,
      price: 0.00739
    },
    {
      round: 9,
      nfts: 357,
      price: 0.00776
    },
    {
      round: 10,
      nfts: 375,
      price: 0.00814
    },
    {
      round: 11,
      nfts: 394,
      price: 0.00855
    },
    {
      round: 12,
      nfts: 414,
      price: 0.00898
    },
    {
      round: 13,
      nfts: 434,
      price: 0.00943
    },
    {
      round: 14,
      nfts: 456,
      price: 0.0099
    },
    {
      round: 15,
      nfts: 479,
      price: 0.01039
    },
    {
      round: 16,
      nfts: 503,
      price: 0.01091
    },
    {
      round: 17,
      nfts: 528,
      price: 0.01146
    },
    {
      round: 19,
      nfts: 554,
      price: 0.01203
    },
    {
      round: 20,
      nfts: 582,
      price: 0.01263
    },
    {
      round: 21,
      nfts: 611,
      price: 0.01327
    },
    {
      round: 22,
      nfts: 40004,
      price: 0.0168
    },
    {
      round: 23,
      nfts: 100,
      price: 0.048
    },
    {
      round: 24,
      nfts: 100,
      price: 0.048 + (0.01)
    },
    {
      round: 25,
      nfts: 100,
      price: 0.048 + (0.01 * 2)
    },
    {
      round: 26,
      nfts: 100,
      price: 0.048 + (0.01 * 3)
    },
    {
      round: 27,
      nfts: 100,
      price: 0.048 + (0.01 * 4)
    },
    {
      round: 28,
      nfts: 100,
      price: 0.048 + (0.01 * 5)
    },
    {
      round: 29,
      nfts: 100,
      price: 0.048 + (0.01 * 6)
    },
    {
      round: 30,
      nfts: 100,
      price: 0.048 + (0.01 * 7)
    },
    {
      round: 31,
      nfts: 100,
      price: 0.048 + (0.01 * 8)
    },
    {
      round: 32,
      nfts: 100,
      price: 0.048 + (0.01 * 9)
    },
    {
      round: 33,
      nfts: 100,
      price: 0.048 + (0.01 * 10)
    },
    {
      round: 34,
      nfts: 100,
      price: 0.048 + (0.01 * 11)
    },
    {
      round: 35,
      nfts: 100,
      price: 0.048 + (0.01 * 12)
    },
    {
      round: 36,
      nfts: 100,
      price: 0.048 + (0.01 * 13)
    },
    {
      round: 37,
      nfts: 100,
      price: 0.048 + (0.01 * 14)
    },
    {
      round: 38,
      nfts: 100,
      price: 0.048 + (0.01 * 15)
    }, {
      round: 39,
      nfts: 100,
      price: 0.048 + (0.01 * 16)
    },
    {
      round: 40,
      nfts: 100,
      price: 0.048 + (0.01 * 17)
    },
  ]
  const [colors, setColors] = useState([]);
  // const colors = [
  //   "assets/images/12/1.jpg",
  //   "assets/images/12/2.jpg",
  //   "assets/images/12/3.jpg",
  //   "assets/images/12/4.jpg",
  //   "assets/images/12/4.jpg",
  //   "assets/images/12/4.jpg",
  //   "assets/images/12/4.jpg",
  //   "assets/images/12/4.jpg",
  //   // "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/8f22b29d34e24238ad80b24b9ad97503%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230506130729.jpg",
  //   // "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/4cdce2af289045b9bfce6f08d8fbba1460c05ec3670f2.png",
  //   // "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/45dccecd71434951ac726d21dec73891%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
  // ];
  const delay = 2500;
  // let intervalLoadData;

  const [indexss, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const timeoutRefGetData = React.useRef(null);

  const resetTimeoutGetData = () => {
    if (timeoutRefGetData.current) {
      clearInterval(timeoutRefGetData.current);
    }
  }

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const unstake = async () => {
    setLoading(true);
    let data = await unStakeNft();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
  }

  const withdrawNfts = async () => {
    setLoading(true);
    let data = await stakeNftWithdraw();
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
  }

  useEffect(() => {
    getData();
    resetTimeout();
    timeoutRefGetData.current = setInterval(getData, 3000);
    return () => {
      resetTimeoutGetData();
    };
  }, []);

  // useEffect(async () => {

  //   getData();
  //   const intervalLoadData = setInterval(getData, 5000);

  //   return () => {
  //     clearInterval(intervalLoadData);
  //   };

  //   // getData()
  //   // intervalLoadData = setInterval(() => {
  //   //   getData()
  //   // }, 1000);
  //   // clearInterval
  // }, []);

  const getData = async () => {
    // console.log('eunnnn');
    // const timeStart = Date.now();
    // let dataInf = await getInfoBuyNfts();
    // let listNfts = await getNft();
    // let balancefnft_ = await getBalanceFnft();

    const dataInfPromise = getInfoBuyNfts();
    const listNftsPromise = getNft();
    const balancefnft_Promise = getBalanceFnft();

    const [dataInf, listNfts, balancefnft_] = await Promise.all([dataInfPromise, listNftsPromise, balancefnft_Promise]);

    // console.log(Date.now() - timeStart);
    if (dataInf == undefined) {
      return
    }
    if (balancefnft_) {
      setBalanceFnft(balancefnft_ / 1e18)
    }

    setDataBuy(dataInf);
    setIsMyNft(listNfts);
    // console.log(dataInf, listNfts);
    if (listNfts.length > 0) {
      let dataStakeNftsst = await getDataStakeNft(listNfts[0].id);
      setPriceBuy(dataStakeNftsst.price);
      setLimit(dataStakeNftsst.limit);
      // console.log(dataStakeNftsst);
      let slkd = 48000, slkdRound = 0, roundst = 0;
      for (let index = 0; index < infoBuy.length; index++) {
        const element = infoBuy[index];
        if (element.price == (dataStakeNftsst.price / 1e18)) {
          slkdRound = element.nfts;
          roundst = element.round;
          break;
        }
        slkd -= element.nfts;
      }
      setDataTotalBuy({ a: slkd, b: slkdRound, c: roundst })
      // console.log('lewlelwel');
      // console.log(slkd, slkdRound);
      setApprove(dataStakeNftsst.stt)

      let dataStakenftFC = dataStakeNftsst.dataStakeNft;
      dataStakenftFC.story = dataStakeNftsst.typeNft;
      dataStakenftFC.amount = (dataStakenftFC.amount) / 1e18;
      dataStakenftFC.received = (dataStakenftFC.received) / 1e18;

      setInterval(() => {
        let times = Date.now() - (dataStakenftFC.timestamp * 1000);

        let totalf1s = dataStakenftFC.totalF1 * 1;
        if (totalf1s > 100) {
          totalf1s = 100;
        }
        // console.log(dataStakenftFC);
        // console.log(((dataStakenftFC.amount * times * (dataStakenftFC.pt + (totalf1s * 3))) / 25920000000) - dataStakenftFC.received);
        dataStakenftFC.profit = ((dataStakenftFC.amount * times * (Number(dataStakenftFC.pt) + (totalf1s * 3))) / 25920000000000) - dataStakenftFC.received;
        // console.log(dataStakenftFC.profit);
        setProfit(dataStakenftFC.profit)
      }, 50);

      if (dataStakenftFC.amount == 0) {
        dataStakenftFC.pt = 0
      }
      // console.log(dataStakenftFC);
      let arr = [], arr1 = [];
      arr1 = JSON.parse("[" + dataStakenftFC.Nfts.slice(1) + "]");
      // console.log(arr1);
      for (let index = 0; index < arr1.length; index++) {
        const element = arr1[index];
        let sotry = await getStoryNfts(element);
        arr.push(`assets/images/12/${sotry}.jpg`)
      }
      // console.log(arr);
      setColors(arr)
      setDataNft(dataStakenftFC)
    }
    else {
      let dataStakeNftsst = await getDataStakeNft(-1);
      let slkd = 48000, slkdRound = 0, roundst = 0;
      for (let index = 0; index < infoBuy.length; index++) {
        const element = infoBuy[index];
        if (element.price == (dataStakeNftsst.price / 1e18)) {
          slkdRound = element.nfts;
          roundst = element.round;
          break;
        }
        slkd -= element.nfts;
      }
      setDataTotalBuy({ a: slkd, b: slkdRound, c: roundst })
      setPriceBuy(dataStakeNftsst.price);
      setLimit(dataStakeNftsst.limit);
      // console.log(dataStakeNftsst);
      setApprove(dataStakeNftsst.stt)
      // console.log(listNfts);
      // console.log(dataStakeNftsst.dataStakeNft.pt);
      if (dataStakeNftsst.dataStakeNft.pt != "0") {

        let dataStakenftFC = dataStakeNftsst.dataStakeNft;
        // dataStakenftFC.story = dataStakeNftsst.typeNft;
        dataStakenftFC.amount = (dataStakenftFC.amount) / 1e18;
        dataStakenftFC.received = (dataStakenftFC.received) / 1e18;

        setInterval(() => {
          // let times = Math.floor(Date.now() / 1000) - dataStakenftFC.timestamp;
          let times = Date.now() - (dataStakenftFC.timestamp * 1000);

          let totalf1s = dataStakenftFC.totalF1 * 1;
          if (totalf1s > 100) {
            totalf1s = 100;
          }
          dataStakenftFC.profit = ((dataStakenftFC.amount * times * (Number(dataStakenftFC.pt) + (totalf1s * 3))) / 25920000000000) - dataStakenftFC.received;
          setProfit(dataStakenftFC.profit)
        }, 50);

        if (dataStakenftFC.amount == 0) {
          dataStakenftFC.pt = 0
        }
        // console.log(dataStakenftFC);
        let arr = [], arr1 = [];
        arr1 = JSON.parse("[" + dataStakenftFC.Nfts.slice(1) + "]");
        // console.log(arr1);
        for (let index = 0; index < arr1.length; index++) {
          const element = arr1[index];
          let sotry = await getStoryNfts(element);
          arr.push(`assets/images/12/${sotry}.jpg`)
        }
        // console.log(arr);
        setColors(arr)
        setDataNft(dataStakenftFC)
      }
    }
    // console.log(Date.now() - timeStart);
  }

  useEffect(() => {
    // console.log(colors.length);
    if (colors.length <= 1) {
      return
    }
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [indexss]);

  useEffect(() => {
    // console.log(colors.length, counda);

    if (colors.length && counda == 0) {
      counda = 1
      setIndex(colors.length - 1);
    }
  }, [colors]);


  // console.log(dataNft.Nfts);
  return (
    <div className="site-main-content__home">

      {/* <div className="slideshow">
        <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {colors.map((backgroundColor, index) => (
            <div
              className="slide"
              key={index}
              style={{ backgroundColor }}
            >
              <img src={backgroundColor}></img>
            </div>
          ))}
        </div>

        <div className="slideshowDots">
          {colors.map((_, idx) => (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div>
      </div> */}

      <div className="alalkks row m-0">

        <div className="col-12" style={{ textAlign: 'center' }}>
          {/* <div className="sssooso" style={{ fontSize: 18 }}>
            Price FNFT
          </div>
          <div className="ooos0s00" style={{ fontSize: 22 }}>
            0.0093$
          </div> */}
          <div>
            Balance: {Number(balanceFnft).toFixed(2)} FNFT
          </div>
        </div>
      </div>

      <h2 className="cbbb mt-4">Network-wide statistics</h2>
      <div className="alalkks row m-0">

        <div className="oaooallwe col-6">
          <div className="sssooso">
            Available Quantity
          </div>
          <div className="ooos0s00">
            {Number(limit / 1)}/{500} NFTs
          </div>
        </div>
        <div className="oaooallwe col-6">
          <div className="sssooso">
            Price Nft
          </div>
          <div className="ooos0s00">
            {((priceBuy) / 1e18).toFixed(3)} BNB
          </div>
        </div>
        {/* <div className="oaooallwe col-6">
          <div className="sssooso">
            Mined Tokens
          </div>
          <div className="ooos0s00">
            {((dataNft?.profitTotal * (dataTotalBuy?.a * 1) - ((dataTotalBuy?.b * 1) - Number(limit / 1))) / 1e18).toFixed(2)}
          </div>
        </div> */}
        {/* <div className="oaooallwe col-6">
          <div className="sssooso">
            Total NFTs v1
          </div>
          <div className="ooos0s00">
            {(dataTotalBuy?.a * 1) - ((dataTotalBuy?.b * 1) - Number(limit / 1))}/48000 NFTs
          </div>
        </div> */}
        {/* <div className="oaooallwe col-6">
          <div className="sssooso">
            Total Supply FNFT
          </div>
          <div className="ooos0s00">
            21.000.000.000 FNFT
          </div>
        </div> */}
        {/* <div className="llkkkkssa">
          <button className="btn btn-primary lllakaks3 mr-2">Detailed</button>
          <button className="btn btn-primary">Adversion</button>
        </div> */}
      </div>
      {
        dataNft.Nfts == undefined || dataNft.Nfts == "" ?
          <div className="myNftstw mt-4">
            <h2 className="cbbb">My NFT</h2>
            <button class="btn btn-primary" style={{ width: "100%" }}>
              <Link
                to="/nfts"
                style={{ textDecoration: "initial", color: "#fff" }}
              >
                Get my exclusive role
              </Link>
            </button>
          </div>
          :
          <div className="myNftstw mt-4">
            <h2 className="cbbb">My NFT</h2>
            <div className="ollskjsa d-flex">
              <div className="lllakkass col-6 p-1">
                {/* <img className="llalsksasa" src={`assets/images/12/${dataNft?.story}.jpg`} /> */}
                <div className="slideshow m-0" style={{ position: 'relative' }}>
                  <div
                    className="slideshowSlider"
                    style={{ transform: `translate3d(${-indexss * 100}%, 0, 0)` }}
                  >
                    {colors.map((backgroundColor, indexss) => (
                      <div
                        className="slide"
                        key={indexss}
                        style={{ backgroundColor }}
                      >
                        <img src={backgroundColor}></img>
                      </div>
                    ))}
                  </div>
                  <div className="slideshowDots" style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                    {
                      colors?.length
                    }
                  </div>
                  {/* <div className="slideshowDots" style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                    {colors.map((_, idx) => (
                      <div
                        key={idx}
                        className={`slideshowDot${indexss === idx ? " active" : ""}`}
                        onClick={() => {
                          setIndex(idx);
                        }}
                      ></div>
                    ))}
                  </div> */}
                </div>
              </div>
              <div className="lllakkass col-6 p-1" style={{ fontSize: 14 }}>
                <div className="assfdqaw">
                  <div className="ssslalka">
                    <div className="lllakkscb">Amount</div>
                    <div className="lmmbvfdjs">{Number(dataNft?.amount).toFixed(2)}</div>
                  </div>
                  <div className="ssslalka">
                    <div className="lllakkscb">Speed</div>
                    <div className="lmmbvfdjs">{(Number(dataNft?.pt) + dataNft?.totalF1 * 3) / 300}%</div>
                  </div>
                  {/* <div className="ssslalka">
                    <div className="lllakkscb">City</div>
                    <div className="lmmbvfdjs">{dataNft?.city}</div>
                  </div> */}
                  {/* <div className="ssslalka">
                    <div className="lllakkscb">Acceleration value</div>
                    <div className="lmmbvfdjs">{dataNft?.acl}</div>
                  </div> */}

                  <div className="ssslalka">
                    <div className="lllakkscb">Total F1</div>
                    <div className="lmmbvfdjs">{dataNft?.totalF1}</div>
                  </div>
                  <div className="ssslalka">
                    <div className="lllakkscb">Withdrawn</div>
                    <div className="lmmbvfdjs">{Number(dataNft?.received).toFixed(2)}  <span style={{ fontSize: 9 }}> FNFT</span></div>

                  </div>
                  <div className="ssslalka">
                    <div className="lllakkscb">Income</div>
                    <div className="lmmbvfdjs">{Number(profit).toFixed(6)} <span style={{ fontSize: 9 }}>FNFT</span></div>
                  </div>
                  <div className="llkkkkssa gsfsdfas" style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                    {/* <button className="btn btn-primary lllakaks3">UnStake</button> */}
                    {loading ? (
                      <LoadingButton className="btn btn-primary" />
                    ) : (
                      <div>
                        <button className="btn btn-primary lllakaks3" onClick={() => { unstake() }}>UnMining</button>
                      </div>
                    )}
                    {loading ? (
                      <LoadingButton className="btn btn-primary" />
                    ) : (
                      <div>
                        <button className="btn btn-primary" onClick={() => { withdrawNfts() }}>Receive</button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <h2 className="cbbb mt-4">City Construction</h2> */}
            {/* {
              loading ?
                <div style={{ textAlign: "center" }}>
                  <img src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca_w200.gif" />
                </div>
                :
                <div className="lloalsldlas row">
                  <div className={dataNft?.city == 1 ? "active col-4" : "col-4"} onClick={() => {
                    fcstakeNfts(1)
                  }}>
                    <div>Central City</div>
                    <img src="https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/1d6873351eb2453d8e5c43f86148e620%E4%B8%AD%E5%A4%AE%E5%9F%8E.png" />
                  </div>
                  <div className={dataNft?.city == 2 ? "active col-4" : "col-4"} onClick={() => {
                    fcstakeNfts(2)
                  }}>
                    <div>Financial City</div>
                    <img src="https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/9b5dcf1c375a447aa0a6b70f7c8794b0智慧城.png" />
                  </div>
                  <div className={dataNft?.city == 3 ? "active col-4" : "col-4"} onClick={() => {
                    fcstakeNfts(3)
                  }}>
                    <div>Zhihui City</div>
                    <img src="https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/13ce4748889c4ad5ad573cbc8de07904科技城.png" />
                  </div>
                  <div className={dataNft?.city == 4 ? "active col-4" : "col-4"} onClick={() => {
                    fcstakeNfts(4)
                  }}>
                    <div>Tech City</div>
                    <img src="https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/b5f70a73f1484eeeaeab1943760995a3未来城.png" />
                  </div>
                  <div className={dataNft?.city == 5 ? "active col-4" : "col-4"} onClick={() => {
                    fcstakeNfts(5)
                  }}>
                    <div>Future City</div>
                    <img src="https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/a7c3e2a3ace74d0c86c28781af4bbc10金融城.png" />
                  </div>
                  <div className={dataNft?.city == 6 ? "active col-4" : "col-4"} onClick={() => {
                    fcstakeNfts(6)
                  }}>
                    <div>AI City</div>
                    <img src="https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/ac872056d2da451a9f48d9315a3f7e6c36e81401b42427d445975b7107973ca.png" />
                  </div>
                </div>

            } */}

          </div>
      }
      <div className="alalkks bg_box" style={{ display: 'inherit', marginTop: 20 }}>
        <button class="btn btn-primary mb-2" style={{ width: "100%" }}><a href="/invite" style={{ textDecoration: "none", color: "#fff" }}>Increase mining productivity FNFT</a></button>
        <span>Note:</span>
        <br />
        <span>
          Every 1 nft will have a default mining yield of 0.1%/day.
          to increase mining productivity. You need to refer more friends to join. for every 1 participant productivity will increase by 0.01%.
          max 1%/day.
        </span>
      </div>
      {/* <img style={{ width: "100%", marginTop: 50 }} src="https://evg.vecoin.vip/static/img/hyjc.e51c33fe.png" /> */}
      {/* <h2 className="cbbb mt-4">Network-wide statistics</h2> */}
      <div className="lllkaksk">
        <a href="https://t.me/fnftaiofficial">
          <img className="llslimg" src="/assets/images/icon/7.png" />
        </a>
        <a href="">
          <img className="llslimg" src="/assets/images/icon/2.png" />
        </a>
        <a href="">
          <img className="llslimg" src="/assets/images/icon/4.png" />
        </a>
        <a href="https://www.youtube.com/@fnftaiofficial">
          <img className="llslimg" src="/assets/images/icon/9.png" />
        </a>
        <a href="https://twitter.com/fnftaiofficial">
          <img className="llslimg" src="/assets/images/icon/8.png" />
        </a>
      </div>
      {/* <div className="myNftstw mt-4">
        <h2>My NFT</h2>
        <button class="btn btn-primary" style={{ width: "100%" }}>Get my exclusive role</button>
      </div> */}


    </div>
  );
};

export default Home;

// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const NEWS = (props) => {
  const state = useSelector((state) => state);

  const [show, setShow] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isMyNft, setIsMyNft] = useState("my");
  const [dataNft, setDataNft] = useState({});
  const handleClose = () => setShow(false);

  const colors = [
    // "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/8f22b29d34e24238ad80b24b9ad97503%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230506130729.jpg",
    "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/4cdce2af289045b9bfce6f08d8fbba1460c05ec3670f2.png",
    "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/45dccecd71434951ac726d21dec73891%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
  ];
  const delay = 2500;

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  // useEffect(() => {
  //   resetTimeout();
  //   timeoutRef.current = setTimeout(
  //     () =>
  //       setIndex((prevIndex) =>
  //         prevIndex === colors.length - 1 ? 0 : prevIndex + 1
  //       ),
  //     delay
  //   );
  // }, [1000]);

  return (
    <div className="site-main-content__home sskskskkssa">
      {/* <p style={{ color: 'rgb(255 75 75)', fontSize: 18 }} className="ffffeasd">
        Projected for 2024, the new project is developing rapidly. However, your strong response has encouraged us to thrive together.
      </p> */}

      <div style={{ fontFamily: 'Arial, sans-serif', justifyContent: 'center', alignItems: 'center' }} className="mt-4 mb-4">
        <div style={{ maxWidth: '600px', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}>
          <h3 style={{ color: '#333' }}>🎉 Important Announcement! 🎁</h3>
          <p style={{ color: '#555' }}>We are pleased to announce that on March 08, 2024, at 2:00 PM (UTC), the Egg Hunting Game will officially launch. All information, rules, gameplay, etc., will be announced on all social media platforms of the project. Get ready to experience exciting surprises!</p>
          <p style={{ color: '#555' }}>Thank you for your support!</p>
          {/* <div style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', borderRadius: '5px', textAlign: 'center', marginTop: '20px' }}>Learn More</div> */}
        </div>
      </div>

      {/* <p style={{ color: '#ededed', fontSize: 15 }} className="ffffeasd">
        <div>
          * The upcoming features...
          <br />
          - Collect 12 NFTs to swap for dynamic NFTs.<br />
          - Every day, a certain amount of BNB will be allocated to enhance liquidity for the community.<br />
          - NFTs can be purchased using tokens.<br />
          - We will introduce a mechanism for bulk purchases simultaneously.<br />
        </div>

        <div>
          * Articles about the project
          <br />
          <a target="_black" href="https://www.scoopnix.com/fnft-unleashing-the-power-of-chinese-zodiac-nfts/">Scoopnix</a>
          <br />
          <a target="_black" href="https://blockonews.com/discover-your-destiny-the-chinese-zodiac-signs-and-their-meanings/">Blockonews</a>
        </div>
      </p> */}

    </div>
  );
};

export default NEWS;

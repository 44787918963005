// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";

const CMS = (props) => {

  return (
    <div className="site-main-content__home">
      <p style={{textAlign: 'center', marginTop: 20}}>Coming soon</p>
    </div>
  );
};

export default CMS;

// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {
  get_info_reward, fnft_reward, get_mission
} from "service";
import { NotificationManager } from "react-notifications";

const LoadingButton = ({ className }) => {
  return (
    <button className={className} style={{ width: "100%" }}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Home = (props) => {
  const state = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [dataReward, setDataReward] = useState({});
  const [nv, setNV] = useState([
    // { mission: "Follow Tiwter", stt: "0", titleLink: "Follow", link: 'https://twitter.com/fnftaiofficial' },
    // { mission: "Join the community", stt: "0", titleLink: "Join", link: 'https://t.me/fnftaiofficial' },
    // { mission: "Subscribe Channel", stt: "0", titleLink: "Subscribe", link: 'https://www.youtube.com/@fnftaiofficial' },
    // // { mission: "Chainlink is the industry-standar and data of today with the", stt: "0", titleLink: "Web3 Services Platform", link: '' }

  ]);
  const handleClose = () => setShow(false);

  const colors = [
    // "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/8f22b29d34e24238ad80b24b9ad97503%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230506130729.jpg",
    // "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/4cdce2af289045b9bfce6f08d8fbba1460c05ec3670f2.png",
    "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/45dccecd71434951ac726d21dec73891%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
  ];
  const delay = 2500;

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  useEffect(async () => {
    getData()
  }, []);

  const getData = async () => {
    let datakss = await get_info_reward();
    let dataListMis = await get_mission();
    if (datakss.data != undefined) {
      setDataReward(datakss.data);
    }
    if (dataListMis.data != undefined) {
      let reArrMission = [];
      for (let index = 0; index < dataListMis.data.length; index++) {
        const element = dataListMis.data[index];
        // console.log(element);
        if (datakss.data !== undefined) {
          // updateGetCMM
          // console.log(element);
          if (element.amount == 0.000006) {
            let teimes = datakss.data.updateGetCMM;
            if (!teimes) {
              teimes = 0;
            }
            // console.log(teimes);
            if (teimes + 86400000 > Date.now()) {
              element.stt = '1';
            }
          } else {
            if (datakss.data.arrMission.indexOf(element.id) != -1) {
              element.stt = '1';
            }
          }

        }
      }
      setNV(dataListMis.data)
    }
    console.log(datakss, dataListMis);
  }
  const getReward = async (dts) => {
    console.log(dts);
    console.log();
    let usf;
    if (dts.amount == 0.000006) {
      usf = "";
    } else {
      usf = document.getElementById(`ip${dts.id}`).value;
    }
    // userAddress, mission, username, idMission
    if (usf == '' && dts.amount != 0.000006) {
      NotificationManager.error("Error", "Please enter username", 10000);
      return
    }

    setLoading(true);
    let data = await fnft_reward(dts.mission, usf, dts.id, dts.amount);
    console.log(data);
    await getData();
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
  }

  return (
    <div className="site-main-content__home">
      <div className="slideshow">
        {/* <div
          className="slideshowSlider"
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
          {colors.map((backgroundColor, index) => (
            <div
              className="slide"
              key={index}
              style={{ backgroundColor }}
            >
              <img src={backgroundColor}></img>
            </div>
          ))}
        </div> */}

        {/* <div className="slideshowDots">
          {colors.map((_, idx) => (
            <div
              key={idx}
              className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                setIndex(idx);
              }}
            ></div>
          ))}
        </div> */}
      </div>
      <div className="alalkks">
        <div className="oaooallwe">
          <div className="sssooso">
            Balance BNB
          </div>
          <div className="ooos0s00">
            {Number(dataReward?.balance).toFixed(6)}
          </div>
        </div>
        <div className="llkkkkssa">
          {/* <button className="btn btn-primary lllakaks3 mr-2">Detailed</button> */}
          <button className="btn btn-primary">Increase</button>
        </div>
      </div>
      <div className="skkskska">
        {
          nv.map(childdt => {
            return (
              <div className="lslallff">
                <div style={{ paddingRight: 15 }}>
                  <p>{childdt.mission} - {childdt.nameId}</p>
                  {
                    childdt.amount == 0.000006 ?
                      null
                      :
                      <div style={{ display: "flex" }}>
                        <a href={childdt.link} target="_blank">
                          {childdt.titleLink}
                        </a>
                        <input id={`ip${childdt.id}`} className="pspwpwp" style={{ maxWidth: 100, marginLeft: 10 }} placeholder="Enter Username" />
                      </div>
                  }

                </div>
                <div>
                  {
                    childdt.stt != "1" ?
                      (
                        loading ? (
                          <LoadingButton className="btn btn-primary" />
                        ) : (
                          <button className="btn btn-primary" onClick={() => { getReward(childdt) }}>Receive <div>({childdt.amount} BNB)</div></button>
                        )
                      )
                      :
                      <button className="btn btn-primary dldllss">Receive</button>
                  }
                  {/* {childdt.stt} */}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default Home;

// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {
  getMyTeam, buyNftWithdraw, updateVersions
} from "service";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

const LoadingButton = ({ className }) => {
  return (
    <button className={className} style={{ width: "100%" }}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Home = (props) => {
  const state = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [dataTeam, setDataTeam] = useState({});

  const withdrawPY = async () => {
    setLoading(true);
    let data = await buyNftWithdraw();
    // console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
  }

  const update = async () => {
    setLoading(true);
    let data = await updateVersions();
    // console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);
  }



  useEffect(async () => {
    getData()
    setInterval(() => {
      getData()
    }, 1000);
  }, []);

  const getData = async () => {
    let dataInf = await getMyTeam();
    // let listNfts = await getNft();
    setDataTeam(dataInf);
    // setIsMyNft(listNfts);
    // console.log(dataInf);
  }
  // console.log(state);
  // console.log(isMyNft, dataNft?.pt);

  const getLevel = (percents) => {
    switch (percents * 1) {
      case 2:
        return 1;
      case 5:
        return 2;
      case 10:
        return 3;
      case 15:
        return 4;
      case 25:
        return 5;
      case 50:
        return 6;
      default:
        return 0;
    }
  }
  return (
    <div className="site-main-content__home">

      <div className="slideshow">
      </div>

      <div className="myNftstw mt-4">
        <h2 className="cbbb">Information</h2>
        <div className="lllakkass col-12 p-1">
          <div className="assfdqaw">
            <div className="ssslalka">
              <div className="lllakkscb">Address</div>
              <div className="lmmbvfdjs">{state?.user?.userAddress.slice(0, 6)}...{state?.user?.userAddress.slice(-6)}</div>
            </div>
            <div className="ssslalka">
              <div className="lllakkscb">Ref By</div>
              <div className="lmmbvfdjs">{state?.user?.ref.slice(0, 6)}...{state?.user?.ref.slice(-6)}</div>
            </div>
            {/* <div className="ssslalka">
              <div className="lllakkscb">Total Nfts F1</div>
              <div className="lmmbvfdjs">{dataTeam?.totalF1} NFTs</div>
            </div> */}
            <div className="ssslalka">
              <div className="lllakkscb">Commission</div>
              <div className="lmmbvfdjs">{dataTeam?.commission / 1e18} BNB</div>
            </div>
            <div className="ssslalka">
              <div className="lllakkscb">DirectSales</div>
              <div className="lmmbvfdjs">{dataTeam?.directSales / 1e18} BNB</div>
            </div>
            <div className="ssslalka">
              <div className="lllakkscb">Level</div>
              <div className="lmmbvfdjs">{getLevel(dataTeam?.percent)}</div>
            </div>
            <div className="ssslalka">
              <div className="lllakkscb">Percent Commission</div>
              <div className="lmmbvfdjs">{dataTeam?.percent}%</div>
            </div>
            <div className="ssslalka">
              <div className="lllakkscb">Received</div>
              <div className="lmmbvfdjs">{dataTeam?.received / 1e18} BNB</div>
            </div>
            <div className="ssslalka">
              <div className="lllakkscb">Availability</div>
              <div className="lmmbvfdjs">{(dataTeam?.commission / 1e18) - (dataTeam?.received / 1e18)} BNB</div>
            </div>

            {/* <div className="ssslalka">
              <div className="lllakkscb">Withdrawn</div>
              <div className="lmmbvfdjs">{Number(dataNft?.received).toFixed(2)} <span style={{ fontSize: 9 }}>FNFT</span></div>
            </div>
            <div className="ssslalka">
              <div className="lllakkscb">Income</div>
              <div className="lmmbvfdjs">{Number(dataNft?.profit).toFixed(4)} <span style={{ fontSize: 9 }}>FNFT</span></div>
            </div> */}
            <div className="llkkkkssa gsfsdfas" style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
              {loading ? (
                <LoadingButton className="btn btn-primary" />
              ) : (
                <div>
                  <button className="btn btn-primary" onClick={() => { withdrawPY() }}>Withdraw</button>
                </div>
              )}
            </div>
            {
              !dataTeam?.isUpdated ?
                <div className="llkkkkssa gsfsdfas" style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                  {loading ? (
                    <LoadingButton className="btn btn-primary" />
                  ) : (
                    <div>
                      <button className="btn btn-primary" onClick={() => { update() }}>Update Version</button>
                    </div>
                  )}
                </div>
                : null
            }
          </div>
        </div>
      </div>

      <div className="lllkaksk">
        <a href="https://t.me/fnftaiofficial">
          <img className="llslimg" src="/assets/images/icon/7.png" />
        </a>
        <a href="">
          <img className="llslimg" src="/assets/images/icon/2.png" />
        </a>
        <a href="">
          <img className="llslimg" src="/assets/images/icon/4.png" />
        </a>
        <a href="https://www.youtube.com/@fnftaiofficial">
          <img className="llslimg" src="/assets/images/icon/9.png" />
        </a>
        <a href="https://twitter.com/fnftaiofficial">
          <img className="llslimg" src="/assets/images/icon/8.png" />
        </a>
      </div>

    </div>
  );
};

export default Home;

import Layout from "components/Layout";
import Home from "views/pages/home";
import Box from "views/pages/box";
import About from "views/pages/about";
import Invite from "views/pages/invite";
import Reward from "views/pages/reward";
import NFT from "views/pages/nfts";
import Team from "views/pages/team";
import CMS from "views/pages/cms";
import NEWS from "views/pages/news";


export const router = [

  // {
  //   title: "Promotion",
  //   path: "/safety/promotion",
  //   component: Promotion,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/622-6229100_stack-of-coins-icon-clipart-computer-icons-coin.svg",
  // },
  // {
  //   title: "Buy SFT",
  //   path: "/safety",
  //   component: null,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-wallet.svg",
  //   children: [
  //     {
  //       title: "Seedround",
  //       path: "/safety/seedround",
  //       component: Seedround,
  //       layout: Layout,
  //       exact: false,
  //       symbol: "Seedround",
  //     },
  //     {
  //       title: "Private",
  //       path: "/safety/private",
  //       component: Private,
  //       layout: Layout,
  //       exact: false,
  //       symbol: "privateSale",
  //     },
  //   ],
  // },
  {
    title: "My assets",
    path: "/home",
    component: Home,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/11.png",
  },
  {
    title: "Box",
    path: "/box",
    component: Box,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/10.png",
  },
  {
    title: "Invite link",
    path: "/invite",
    component: Invite,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/5.png",
  },
  {
    title: "Game",
    path: "/game",
    component: CMS,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/6.png",
  },
  {
    title: "My team",
    path: "/team",
    component: Team,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/3.png",
  },
  {
    title: "NFTs",
    path: "/nfts",
    component: NFT,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/6.png",
  },

  {
    title: "Swap",
    path: "/swap",
    component: CMS,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/5.png",
  },
  {
    title: "News",
    path: "/news",
    component: NEWS,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/6.png",
  },
  {
    title: "Reward",
    path: "/reward",
    component: Reward,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/10.png",
  },

  {
    title: "About",
    path: "/about",
    component: About,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/6.png",
  },

  {
    title: "Support",
    path: "https://t.me/scbozwkhrdyva",
    component: null,
    layout: Layout,
    exact: true,
    icon: "/assets/images/icon/6.png",
  },
  // {
  //   title: "NetWorkStaking",
  //   path: "/info",
  //   component: Info,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/information.svg",
  // },
  // {
  //   title: "Staking",
  //   path: "/staking",
  //   component: Staking,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/622-6229100_stack-of-coins-icon-clipart-computer-icons-coin.svg",
  // },

  // {
  //   title: "Staking",
  //   path: "/safety/staking",
  //   component: Staking,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/622-6229100_stack-of-coins-icon-clipart-computer-icons-coin.svg",
  //   children: [
  //     {
  //       title: "V1 ( Old )",
  //       path: "/safety/staking",
  //       component: Staking,
  //       layout: Layout,
  //       exact: false,
  //       symbol: "v1",
  //     },
  //     {
  //       title: "V2 ( New )",
  //       path: "/safety/staking-v2",
  //       component: Stakev2,
  //       layout: Layout,
  //       exact: false,
  //       symbol: "v2",
  //     }
  //   ]
  // },
  // {
  //   title: "Swap",
  //   path: "https://swap.sftdefi.info/swap",
  //   component: null,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-swap.svg",
  // },
  // {
  //   title: "Farms",
  //   path: "/safety/farms",
  //   component: Farms,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-farms.svg",
  // },
  // {
  //   title: "Pools",
  //   path: "/safety/pools",
  //   component: Pools,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-pools.svg",
  // },
  // {
  //   title: "NFT Game",
  //   path: "/safety/NFT-Games",
  //   component: NFTGames,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-NFT-games.svg",
  // },

  // {
  //   title: "Docs",
  //   path: "https://docs.sftdefi.info/",
  //   component: null,
  //   layout: Layout,
  //   exact: true,
  //   icon: "/assets/images/svg-export/icon-docs.svg",
  // },
];

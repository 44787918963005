// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {
  buyNft, getInfoBuyNfts, getNft, stakeNftWithdraw,
  stakeNft, getDataStakeNft, approveAllStakingNft,
  checkApproveNft
} from "service";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";

const LoadingButton = ({ className }) => {
  return (
    <button className={className} style={{ width: "100%" }}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Home = (props) => {
  const state = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const [show, setShow] = useState(true);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isMyNft, setIsMyNft] = useState([]);
  const [dataNft, setDataNft] = useState({});
  const [dataStakeNft, setDataStakeNft] = useState({});
  const [dataBuy, setDataBuy] = useState({});
  const handleClose = () => setShow(false);
  const [showModal, setShowModal] = useState(false);
  const [vlOW, setVlOW] = useState("");
  const [selectCG, setSelectCG] = useState("");

  const colors = [
    // "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/8f22b29d34e24238ad80b24b9ad97503%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230506130729.jpg",
    // "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/4cdce2af289045b9bfce6f08d8fbba1460c05ec3670f2.png",
    "https://vgecoin.oss-cn-hongkong.aliyuncs.com/ve/45dccecd71434951ac726d21dec73891%E6%9C%AA%E6%A0%87%E9%A2%98-1.jpg"
  ];
  const delay = 2500;

  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  const timeoutRefGetData = React.useRef(null);

  const resetTimeoutGetData = () => {
    if (timeoutRefGetData.current) {
      clearInterval(timeoutRefGetData.current);
    }
  }
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  const buyNfts = async (nums) => {
    setSelectCG(nums)
    setShowModal(true)

    // console.log(nums, dataBuy.price);
    // return
    // setLoading(true);
    // let data = await buyNft(nums, dataBuy.price);
    // console.log(data);
    // if (!data) {
    //   NotificationManager.error("Error", "Error", 10000);
    // } else {
    //   NotificationManager.success("Success", "Success", 10000);
    // }
    // setLoading(false);


  }


  // useEffect(async () => {
  //   getData()
  //   setInterval(() => {
  //     getData()
  //   }, 1000);
  // }, []);

  useEffect(() => {
    getData();
    resetTimeout();
    timeoutRefGetData.current = setInterval(getData, 3000);
    return () => {
      resetTimeoutGetData();
    };
  }, []);


  const getData = async () => {

    const dataInfPromise = getInfoBuyNfts();
    const listNftsPromise = getNft();

    const [dataInf, listNfts] = await Promise.all([dataInfPromise, listNftsPromise]);

    // let dataInf = await getInfoBuyNfts();
    // let listNfts = await getNft();
    setDataBuy(dataInf);
    setIsMyNft(listNfts);
    // console.log(listNfts);

  }


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(selectCG, vlOW);
    setLoading(true);
    let data = await buyNft(selectCG, dataBuy.price, vlOW);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      setShowModal(false)
      NotificationManager.success("Success", "Success", 10000);
    }
    setLoading(false);

    // // Call the onTransfer function with the newOwner value
    // // onTransfer(vlOW);
    // setLoading(true);
    // console.log('run');
    // // let _data = await transferLockOwner(lockId, vlOW)
    // // if (_data) {
    // //   succNotic()
    // //   onClose()
    // //   reloadData()
    // // }
    // setLoading(false);
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors?.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  const fcstakeNfts = async (ssssd) => {
    // console.log(nums);
    console.log(ssssd);
    // return
    // if (dataNft?.pt != "0") {
    //   return
    // }
    setLoading(true)
    const _checkApproveNft = await checkApproveNft(ssssd.id);
    console.log(_checkApproveNft);
    // return;
    if (_checkApproveNft == false) {
      await approveAllStakingNft().catch(er => {
        setLoading(false);
        return
      })
    }

    let data = await stakeNft(ssssd.id, 0);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Investment Success", "Success", 10000);
    }
    setLoading(false);
  }
  // console.log(isMyNft, dataNft?.pt);
  return (
    <div className="site-main-content__home">

      <div className="slideshow">
      </div>

      <div className="myNftstw mt-4">
        <h2 className="cbbb">Buy NFT</h2>

        {loading ? (
          <LoadingButton className="btn btn-primary" />
        ) : (
          <div>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(1) }}>Rat</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(2) }}>Ox</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(3) }}>Tiger</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(4) }}>Rabbit</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(5) }}>Dragon</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(6) }}>Snake</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(7) }}>Horse</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(8) }}>Goat</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(9) }}>Monkey</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(10) }}>Rooster</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(11) }}>Dog</button>
            <button class="btn btn-primary sadsas" onClick={() => { buyNfts(12) }}>Pig</button>
          </div>
          // <button class="btn btn-primary" style={{ width: "100%" }}>Get my exclusive role</button>
        )}
      </div>

      <div className="lalsallass">
        <h2 className="cbbb mt-4">My NFTs</h2>
        <div className="lolalalalk row m-0">
          {
            isMyNft.length == 0 ?
              <div>No data!!!</div>
              :
              (
                loading ?
                  <div style={{ textAlign: "center" }}>
                    <img src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca_w200.gif" />
                  </div>
                  :
                  (isMyNft.map((tsjjsjh) => {
                    return (
                      <div className="col-6 mb-4">
                        <img className="olslljfa" src={`assets/images/12/${(tsjjsjh.story * 1 > 12 ? (tsjjsjh.story % 12) : tsjjsjh.story) == 0 ? "1" : (tsjjsjh.story * 1 > 12 ? (tsjjsjh.story % 12) : tsjjsjh.story)}.jpg`} />
                        <div style={{ width: '100%' }} onClick={() => { fcstakeNfts(tsjjsjh) }}>
                          <button class="btn btn-primary sadsas olsllwer23jfa">Mining Now</button>
                        </div>
                      </div>
                    )
                  }))
              )
          }
        </div>
      </div>

      <div className="lllkaksk">
        <a href="https://t.me/fnftaiofficial">
          <img className="llslimg" src="/assets/images/icon/7.png" />
        </a>
        <a href="">
          <img className="llslimg" src="/assets/images/icon/2.png" />
        </a>
        <a href="">
          <img className="llslimg" src="/assets/images/icon/4.png" />
        </a>
        <a href="https://www.youtube.com/@fnftaiofficial">
          <img className="llslimg" src="/assets/images/icon/9.png" />
        </a>
        <a href="https://twitter.com/fnftaiofficial">
          <img className="llslimg" src="/assets/images/icon/8.png" />
        </a>
      </div>

      {/* <div className="myNftstw mt-4">
        <h2>My NFT</h2>
        <button class="btn btn-primary" style={{ width: "100%" }}>Get my exclusive role</button>
      </div> */}

      {
        showModal ?
          <Modal show={show} onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ color: '#fff' }}>Quantity</p>
              <input style={{ width: '100%' }} className="input" type="text" id="newOwner" name="newOwner" value={vlOW} onChange={(e) => setVlOW(e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="secondary" onClick={() => { setShowModal(false) }}>
                Close
              </Button> */}
              <div className="has-text-centered">
                {
                  loading ?
                    <LoadingButton type="button" className="ant-btn ant-btn-primary btn btn-primary" />
                    :
                    (
                      <button type="submit" className="ant-btn ant-btn-primary btn btn-primary" onClick={handleSubmit}>
                        <span>Buy</span>
                      </button>
                    )
                }
              </div>
              {/* <Button variant="primary" onClick={() => { setShowModal(false) }}>
                Buy
              </Button> */}
            </Modal.Footer>
          </Modal>

          // <div className="ant-modal-wrap" style={{ backgroundColor: "rgba(0, 0, 0, .45)" }} tabIndex="-1" role="dialog" aria-labelledby="rcDialogTitle0">
          //   <div role="document" className="ant-modal" style={{ width: '520px', transformOrigin: '156px 568px' }}>
          //     <div tabIndex="0" aria-hidden="true" style={{ width: '0px', height: '0px', overflow: 'hidden', outline: 'none' }}></div>
          //     <div className="ant-modal-content">
          //       <button type="button" aria-label="Close" className="ant-modal-close" onClick={() => { setShowModal(false) }}>
          //         <span className="ant-modal-close-x">
          //           <span role="img" aria-label="close" className="anticon anticon-close ant-modal-close-icon">
          //             <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
          //               <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
          //             </svg>
          //           </span>
          //         </span>
          //       </button>
          //       <div className="ant-modal-header">
          //         <div className="ant-modal-title" id="rcDialogTitle0">Transfer Ownership</div>
          //       </div>
          //       <div className="ant-modal-body">
          //         <form onSubmit={handleSubmit}>
          //           <div className="field">
          //             <label className="label" htmlFor="newOwner">New Owner Address<sup className="has-text-danger">*</sup></label>
          //             <div className="control">
          //               <input className="input" type="text" id="newOwner" name="newOwner" value={vlOW} onChange={(e) => setVlOW(e.target.value)} />
          //             </div>
          //           </div>
          //           <div className="has-text-centered">
          //             {
          //               loading ?
          //                 <LoadingButton type="button" className="ant-btn ant-btn-primary" />
          //                 :
          //                 (
          //                   <button type="submit" className="ant-btn ant-btn-primary">
          //                     <span>Transfer Ownership</span>
          //                   </button>
          //                 )

          //             }

          //           </div>
          //         </form>
          //       </div>
          //     </div>
          //     <div tabIndex="0" aria-hidden="true" style={{ width: '0px', height: '0px', overflow: 'hidden', outline: 'none' }}></div>
          //   </div>
          // </div>
          : null
      }
    </div>
  );
};

export default Home;

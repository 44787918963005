// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import { NotificationManager } from "react-notifications";
import Button from 'react-bootstrap/Button';

const Home = (props) => {
  const state = useSelector((state) => state);

  const [show, setShow] = useState(true);
  console.log(state);
  return (
    <div className="site-main-content__home">
      <div className="lalakskdsado">
        <div className="mb-4">Invite friends QR code</div>
        <img style={{padding: 15, borderRadius: 5, background: 'rgb(21, 23, 26)'}} src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${window.location.origin}/login?ref=${state?.address_web3}`}></img>
        <div className="mt-4 mb-4">Invite friends through links</div>
        <div className="sa22asf">
          <span>{`${window.location.origin?.slice(0, 7)}...${state?.address_web3?.slice(-7)}`}</span>
        </div>
        <button
          className="btn btn-primary ml-3 mt-4"
          style={{
            margin: "0px 10px",
            width: 200,
            padding: "0 10px",
          }}
          onClick={() => {
            let text = `${window.location.origin}/login?ref=${state?.address_web3}`;
            var input = document.createElement("input");
            input.setAttribute("value", text);
            document.body.appendChild(input);
            input.select();
            document.execCommand("copy");
            document.body.removeChild(input);

            NotificationManager.success(
              "Copy Successful",
              "Success",
              10000
            );
          }}
        >
          Copy
        </button>
      </div>
    </div>
  );
};

export default Home;

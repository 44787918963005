import React, { useState, useEffect, useCallback } from 'react';
import { Howl } from 'howler';
import { buyBox, checkApproveBuyBox, formatNumber, submitApproveBox } from 'service';
import Modal from 'react-bootstrap/Modal'
import { NotificationManager } from 'react-notifications';

const LoadingButton = ({ className }) => {
  return (
    <button className={className} style={{ width: 200 }}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [indexImg, setIndexImg] = useState(0);
  const [typeBox, setTypeBox] = useState(3);
  const [arrImg, setArrImg] = useState([]);
  const [approve, setApprove] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalReward, setShowModalReward] = useState(false);
  const [dataReward, setDataReward] = useState({});
  const [vlOW, setVlOW] = useState("");
  let loadedCount = 0;
  const totalImages = 107; // Số lượng ảnh cần tải
  let intervalId;

  const sound = new Howl({
    src: ['/assets/images/soundChess.mp3'],
  });

  const playSound = () => {
    sound.play();
  };

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const getTimeRemaining = () => {
    const now = new Date();
    const targetTime = new Date(now);
    targetTime.setHours(21, 0, 0, 0); // Set the target time to 21:00:00

    let timeDiff = targetTime - now;

    if (timeDiff < 0) {
      // If the target time has passed, set the time difference to 0
      timeDiff = 0;
    }

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    return {
      hours: formatTime(hours),
      minutes: formatTime(minutes),
      seconds: formatTime(seconds),
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 1000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(timer);
  }, []);




  useEffect(() => {
    let arr = [];
    for (let index = 0; index < 107; index++) {
      arr.push(index)
    }
    setArrImg(arr)
    startImageDownload();
  }, []);

  useEffect(() => {
    let arr = [];
    for (let index = 0; index < 107; index++) {
      arr.push(index)
    }
    setArrImg(arr)
    startImageDownload();
    setIndexImg(0)
  }, [typeBox]);


  const handleOpenClick = () => {
    setShowModal(true)
    // setIsOpen(true);

    // setIndexImg(0)
    // let aa = 0;
    // intervalId = setInterval(() => {
    //   if (aa >= 105) {
    //     clearInterval(intervalId);
    //     setIsOpen(false); // Close the "gift box" after the animation completes
    //   }
    //   if (aa == 15) {
    //     playSound();
    //   }
    //   setIndexImg((prevIndex) => prevIndex + 1);
    //   aa++;
    // }, 50);
  };

  useEffect(async () => {
    getData()
    const timer = setInterval(() => {
      getData()
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const getData = async () => {
    let data = await checkApproveBuyBox();
    setApprove(data?.stt);
  }

  const startImageDownload = async () => {
    setIsDownloading(true);
    try {
      // Mảng chứa các promise từ việc tải từng ảnh
      const downloadPromises = Array.from({ length: totalImages }, (_, index) => {
        return loadImage(index);
      });

      // Sử dụng Promise.all để chờ tất cả các ảnh được tải xong
      await Promise.all(downloadPromises);
    } catch (error) {
      console.error('Error loading images:', error);
    } finally {
      setIsDownloading(false);
      setIsOpen(false); // Mở hộp quà khi tất cả ảnh đã được tải xong
    }
  };

  const loadImage = async (index) => {
    try {
      const response = await fetch(`/assets/images/lv${typeBox}/chest2-lv${typeBox}-open_${getindex(index)}.webp`);
      if (response.ok) {
        // Tăng số lượng ảnh đã tải
        loadedCount++;
        setLoadingProgress((loadedCount / totalImages) * 100);
      }
    } catch (error) {
      console.error('Error loading image:', error);
    }
  };

  const getindex = (id) => {
    if (id < 10) {
      return '00' + id;
    }
    if (id < 100) {
      return '0' + id;
    } else {
      return '' + id;
    }
  };
  // console.log(indexImg);


  const approveFVS = async () => {

    setIsOpen(true);
    let data = await submitApproveBox();
    console.log(data);
    // if (!data) {
    //   NotificationManager.error("Error", "Error", 10000);
    // } else {
    //   NotificationManager.success("Success", "Success", 10000);
    // }
    setIsOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(selectCG, vlOW);
    if (vlOW > 10) {
      NotificationManager.error("Max 10", "Error", 10000);
    }
    setLoading(true);
    let data = await buyBox(vlOW, typeBox);
    // console.log(data.events?.BuyNftsResult?.returnValues);
    setDataReward(data.events?.BuyNftsResult?.returnValues)
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      setShowModal(false)
      // NotificationManager.success("Success", "Success", 10000);
      // setShowModal(true)
      setIsOpen(true);

      setIndexImg(0)
      let aa = 0;
      intervalId = setInterval(() => {
        if (aa >= 105) {
          clearInterval(intervalId);
          setIsOpen(false); // Close the "gift box" after the animation completes
        }
        if (aa == 15) {
          playSound();
        }

        if (aa == 70) {
          setShowModal(false)
          setShowModalReward(true)
        }

        setIndexImg((prevIndex) => prevIndex + 1);
        aa++;
      }, 50);
    }
    setLoading(false);
  };


  // console.log(approve);
  return (
    <div className="App">
      <div style={{ height: 20 }}></div>
      <div class="bg_move">
        <h1 style={{ fontSize: 40 }}>Gift Box</h1>
      </div>
      <div style={{ height: 20 }}></div>
      {/* <h2 style={{ textAlign: 'center' }}>Countdown Timer</h2>
      <p style={{ textAlign: 'center', fontSize: 46 }}>
        {timeRemaining.hours}<span className='ssssaffa'>h </span>{timeRemaining.minutes}<span className='ssssaffa'>m </span>{timeRemaining.seconds}<span className='ssssaffa'>s</span>
      </p> */}

      {isDownloading ? (
        <div>
          Downloading... {loadingProgress.toFixed(2)}%
          <progress value={loadingProgress} max="100"></progress>
        </div>
      )
        :
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button className={`ant-btn ant-btn-primary btn btn-primary btn-basic-box btn-box ${typeBox == 1 ? "active" : ""}`} onClick={() => { setTypeBox(1) }} disabled={isOpen}>
              Basic Box
            </button>
            <button className={`ant-btn ant-btn-primary btn btn-primary btn-standard-box btn-box ${typeBox == 2 ? "active" : ""}`} onClick={() => { setTypeBox(2) }} disabled={isOpen}>
              Standard Box
            </button>
            <button className={`ant-btn ant-btn-primary btn btn-primary btn-premium-box btn-box ${typeBox == 3 ? "active" : ""}`} onClick={() => { setTypeBox(3) }} disabled={isOpen}>
              Premium Box
            </button>
          </div>
          {
            arrImg.map((item, index) => {
              return (
                <img
                  className={indexImg != item ? "d-none" : ""}
                  key={index}
                  src={`/assets/images/v${typeBox}/chest2-lv${typeBox}-open_${getindex(item)}.webp`}
                  alt={`Image ${index}`}
                />
              )
            })
          }

          <div style={{ textAlign: 'center' }}>
            <div className='mb-4'>Price: {typeBox == 1 ? "100" : (typeBox == 2 ? "500" : "3,000")} FNFT</div>
            {
              isOpen ?
                <LoadingButton type="button" className="ant-btn ant-btn-primary btn btn-primary" />
                :
                (
                  (
                    approve ?
                      <button style={{ width: 200 }} className="ant-btn ant-btn-primary btn btn-primary" onClick={handleOpenClick} disabled={isOpen}>
                        Open
                      </button>
                      :
                      <button type="submit" className="ant-btn ant-btn-primary btn btn-primary" onClick={approveFVS}>
                        <span>Approve</span>
                      </button>

                  )

                )
            }


          </div>
        </>
      }
      {
        showModal ?
          <Modal show={true} onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ color: '#fff' }}>Quantity</p>
              <input style={{ width: '100%' }} className="input" type="text" id="newOwner" name="newOwner" value={vlOW} onChange={(e) => setVlOW(e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
              <div className="has-text-centered">
                {
                  loading ?
                    <LoadingButton type="button" className="ant-btn ant-btn-primary btn btn-primary" />
                    :
                    (
                      <button type="submit" className="ant-btn ant-btn-primary btn btn-primary" onClick={handleSubmit}>
                        <span>Buy</span>
                      </button>

                    )
                }
              </div>
            </Modal.Footer>
          </Modal>
          : null
      }

      {
        showModalReward ?
          <Modal show={true} onHide={() => { setShowModalReward(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ color: '#fff' }}>Congratulations on receiving</p>
              <div>
                <div className='row m-0 ooooosklsksksaa'>
                  <div className='col-3'>
                    <img src='https://s2.coinmarketcap.com/static/img/coins/64x64/8119.png' />
                    <div className='sssadfdasds' style={{ color: '#fff' }}>+{formatNumber(dataReward?.totalRewardSfp / 1e18)}</div>
                  </div>
                  <div className='col-3'>
                    <img src='https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png' />
                    <div className='sssadfdasds' style={{ color: '#fff' }}>+{formatNumber(dataReward?.totalRewardCake / 1e18)}</div>
                  </div>
                  <div className='col-3'>
                    <img src='https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png' />
                    <div className='sssadfdasds' style={{ color: '#fff' }}>+{formatNumber(dataReward?.totalRewardBnb / 1e18)}</div>
                  </div>
                  <div className='col-3'>
                    <img src='https://png.pngtree.com/png-vector/20220718/ourmid/pngtree-non-fungible-token-logo-design-nft-icon-gradient-hexagonal-png-image_6005258.png' />
                    <div className='sssadfdasds' style={{ color: '#fff' }}>+{(dataReward?.totalRewardNft)}</div>
                  </div>
                </div>
              </div>
            </Modal.Body>

          </Modal>
          : null
      }
    </div>
  );
};

export default App;








import React from "react";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const state = useSelector((state) => state);

  return (
    <>
      <Header props={children.props} />
      <main className="site-main-content container-fluid">
        <div className="site-main-content__wrap">
          <Sidebar />
          <div
            className={
              state.active
                ? "site-main-content__content active"
                : "site-main-content__content"
            }
          >
            {children}
            
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
